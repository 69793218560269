import { Message } from "element-ui";
import firebase from "firebase/app";
import moment from "moment";
import { db } from "@/plugins/firebase";
// 計算 陣列裡 數字總合方法
import { sum as _sum } from "lodash/math";
// empty 方法
import { isEmpty } from "@/service/anyService";
/**
 * 取得使用者資料
 * @param { type String(字串) } userId 使用者 id
 * @returns
 */
export const firebaseGetUserData = async (userId) => {
    try {
        const user = await db().doc(`chat_rooms/${userId}`).get();
        if (!user.exists) {
            return {};
        }
        return user.data();
    } catch (err) {
        console.log("取得chat_rooms 使用者資料失敗", err);
        return err;
    }
};
/**
 * 檢查聊天對象聊天室是否為空的
 * @param { type String(字串) } loginUserId  登入使用者id
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 */
export const firebaseCheckReceiveUserChatRoomEmpty = async (loginUserId, receiveUserId) => {
    try {
        const doc = await db().doc(`chat_rooms/${loginUserId}/users/${receiveUserId}`).get();
        if (!doc.exists) {
            return true;
        }
        return false;
    } catch (err) {
        console.log("檢查聊天室是否存在失敗", err);
        return false;
    }
};
/**
 * 檢查指定使用者是否有建立過 chat_rooms 資料
 * @param { type String(字串) } userId  使用者id
 */
export const firebaseCheckUserChatRoomEmpty = async (userId) => {
    try {
        const doc = await db().doc(`chat_rooms/${userId}`).get();
        // 找不到此 user 聊天室時給 true
        if (!doc.exists) {
            return true;
        }
        return false;
    } catch (err) {
        console.log("檢查聊天室是否存在失敗", err);
        return false;
    }
};

/**
 * 建立使用者聊天室chat_rooms 資料
 * @param { type Object(物件) } userData 使用者資料
 * @param { type String(字串) } loginUserId 登入者 id
 * @returns
 */
export const firebaseSetUserChatRoom = async (userData, loginUserId) => {
    const doc = await db().doc(`chat_rooms/${loginUserId}/`).get();
    if (!doc.exists) {
        try {
            db()
                .doc(`chat_rooms/${loginUserId}`)
                .set({
                    userData,
                    isProvider: userData.role > 0 ? true : false,
                    unReadMessageCount: 0,
                    unReadMessageCountByServiceChat: 0,
                    enableCityAi: false,
                    updatedAt: moment().valueOf(),
                });
        } catch (err) {
            return Message({
                type: "error",
                message: "建立個人資料聊天室失敗",
            });
        }
        return;
    }
    try {
        await db()
            .doc(`chat_rooms/${loginUserId}`)
            .update({
                userData,
                isProvider: userData.role > 0 ? true : false,
                updatedAt: moment().valueOf(),
            });
    } catch (err) {
        return Message({
            type: "error",
            message: "更新個人資料聊天室失敗",
        });
    }
};

/**
 * 設定聊天對象聊天室指定對象資料
 * @param { tpye Object(物件) }  userData 使用者資料
 * @param { type String(字串) } loginUserId  登入使用者id
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 */
export const firebaseChatRooms = async (userData, loginUserId, receiveUserId) => {
    // 檢查是否有聊天室 如果沒有則使用set 方法建立
    const empty = await firebaseCheckReceiveUserChatRoomEmpty(loginUserId, receiveUserId);
    try {
        if (empty) {
            // 判斷是空聊天室時 使用 set 方法建立資料
            await db()
                .doc(`chat_rooms/${loginUserId}/users/${receiveUserId}`)
                .set({
                    userData,
                    isProvider: userData.role > 0 ? true : false,
                });
        } else {
            // 有聊天室時 使用 update 方法更新聊天室資料
            await db()
                .doc(`chat_rooms/${loginUserId}/users/${receiveUserId}`)
                .update({
                    userData,
                    isProvider: userData.role > 0 ? true : false,
                });
        }
        return { success: true };
    } catch (err) {
        console.log("設定聊天室失敗", err);
        return Message({
            type: "error",
            message: "設定聊天室失敗",
        });
    }
};

/**
 * 取得單一對象未讀訊息數量
 * @param { type String(字串) } loginUserId  登入使用者id
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 * @returns
 */
export const firebaseGetReveiveUserUnReadTotal = async (receiveUserId, loginUserId) => {
    try {
        const doc = await db().doc(`chat_rooms/${receiveUserId}/users/${loginUserId}`).get();
        // 判斷沒有資料時回傳 0
        if (doc.empty) {
            return 0;
        }
        // 找不到資料時 回傳預設值 0
        if (doc.data() === undefined) {
            return 0;
        }
        return isEmpty(doc.data().unReadMessageCount) ? 0 : doc.data().unReadMessageCount;
    } catch (err) {
        console.log("取得指定對象聊天數量異常", err);
        return Message({
            type: "error",
            message: "取得指定對象聊天數量異常",
        });
    }
};

/**
 * 更新聊天室 未讀訊息數量 與最後一筆聊天內容
 * @param { type Object(物件) } data 未讀訊息數量 以及最後一個聊天內容 { ex: unReadMessageCount: 20, message: "測試內容" }
 * @param { type String(字串) } loginUserId  登入使用者id
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 */
export const firebaseUnReadCountAndLastMessage = async (data, loginUserId, receiveUserId) => {
    try {
        await db()
            .doc(`chat_rooms/${loginUserId}/users/${receiveUserId}`)
            .update({ ...data, updatedAt: moment().valueOf() });
        return { success: true };
    } catch (err) {
        console.log(`設定未讀數量與最後聊天內容失敗 loginUserId: ${loginUserId}, receiveUserId: ${receiveUserId}`, err);
        return Message({
            type: "error",
            message: " 設定未讀數量與最後聊天內容失敗",
        });
    }
};

/**
 * 取得與客服聊天的未讀訊息數量總計
 * @param { type String(字串) } loginUserId 登入使用者id
 * @returns
 */
export const firebaseGetServiceChatUnReadMessageCount = async (loginUserId) => {
    try {
        const serviceChatData = await db().doc(`chat_rooms/${loginUserId}/users/${process.env.VUE_APP_SERVICE_CHAT_ID}`).get();
        if (serviceChatData.empty) {
            return false;
        }
        return serviceChatData.data().unReadMessageCount === undefined ? 0 : serviceChatData.data().unReadMessageCount;
    } catch (err) {
        console.log("計算客服聊天對象未讀訊息數量異常", err);
        return false;
    }
};
/**
 * 取得指定對象中所有聊天對象未讀訊息總計
 * @param { type String(字串) } userId 使用者id
 */
export const firebaseGetUserUnReadMessageCount = async (userId) => {
    try {
        const user = await db().doc(`chat_rooms/${userId}`).get();
        // 判斷沒有找到客服聊天對象時 回傳預設值0
        if (!user.exists) {
            return 0;
        }
        return user.data().unReadMessageCount;
    } catch (err) {
        console.log("取得指定對象與聊天對象未讀訊息數量統計失敗", err);
        return false;
    }
};
/**
 * 取得 chat_rooms中聊天對象 未讀訊息數量總計 未讀訊息總數量
 * @param { type String(字串) } loginUserId 登入使用者id
 */
export const firebaseGetChatRoomUnReadMessageCountTotal = async (loginUserId) => {
    try {
        // 取得所有聊天對象資料 並 過濾掉客服聊天對象
        const users = await db()
            .collection(`chat_rooms/${loginUserId}/users`)
            .where(firebase.firestore.FieldPath.documentId(), "!=", process.env.VUE_APP_SERVICE_CHAT_ID)
            .get();
        // 沒有取得資料時不往下執行
        if (users.empty) {
            return 0;
        }
        // 未讀訊息數量
        const unReadCounts = [];
        users.forEach((user) => {
            // 計算未讀訊息數量 (判斷是否有未讀訊息數量)
            if (user.data().unReadMessageCount !== undefined) {
                // 判斷非 NaN 對象才加入 unReadCounts
                if (!isNaN(parseInt(user.data().unReadMessageCount))) {
                    // 新增未讀訊息數量
                    unReadCounts.push(parseInt(user.data().unReadMessageCount));
                }
            }
        });
        // 總計未讀訊息數量
        return _sum(unReadCounts);
    } catch (err) {
        console.log("計算chat_rooms 聊天對象未讀訊息數量異常", err);
        return false;
    }
};
/**
 * 取得指定對象與客服聊天未讀訊息數量統計
 * @param { type String(字串) } userId 使用者id
 */
export const firebaseGetUserByServiceChatUnReadMessageCount = async (userId) => {
    try {
        const serviceChat = await db().doc(`chat_rooms/${userId}/users/${process.env.VUE_APP_SERVICE_CHAT_ID}`).get();
        // 判斷沒有找到客服聊天對象時 回傳預設值0
        if (!serviceChat.exists) {
            return 0;
        }
        return serviceChat.data().unReadMessageCount;
    } catch (err) {
        console.log("取得登入者與客服聊天未讀訊息數量統計失敗", err);
        return false;
    }
};

/**
 * 更新聊天對象未讀訊息數量總計
 * @param { type String(字串) } userId 使用者id
 */
export const firebaseUpdateUserUnReadMessageCount = async (userId) => {
    // 聊天室未讀訊息數量
    const chroomsUnReadCount = await firebaseGetChatRoomUnReadMessageCountTotal(userId);
    // 取得自己聊天室未讀訊息數量失敗時 不往下執行
    if (chroomsUnReadCount === false) {
        return;
    }
    try {
        // 更新未讀訊息數量
        await db().doc(`chat_rooms/${userId}`).update({
            unReadMessageCount: chroomsUnReadCount,
            updatedAt: moment().valueOf(),
        });
    } catch (err) {
        console.log("更新 chat_rooms 的未讀訊息數量失敗", err);
        return err;
    }
};

/**
 * 更新登入者與客服聊天室未讀訊息總計數量
 * @param { type String(字串) } userId 使用者id
 * @returns
 */
export const firebaseUpdateUserUnReadMessageCountByServiceChat = async (userId) => {
    // 登入者與客服聊天對象未讀訊息總計數量
    const serviceChatUnReadCount = await firebaseGetUserByServiceChatUnReadMessageCount(userId);
    // 取得登入者與客服聊天對象未讀訊息數量綜計失敗時 不往下執行
    if (serviceChatUnReadCount === false) {
        console.log("取得客服聊天訊息統計失敗");
        return;
    }
    try {
        // 更新登入者與客服聊天室未讀訊息總計數量
        await db().doc(`chat_rooms/${userId}/`).update({
            unReadMessageCountByServiceChat: serviceChatUnReadCount,
            updatedAt: moment().valueOf(),
        });
    } catch (err) {
        console.log("更新登入者與客服聊天室未讀訊息總計數量失敗", err);
        return err;
    }
};

/**
 * 設定聊天對象 未讀訊息數量 以及 最後一個 訊息內容
 * @param { type Number(數字) }  unReadCount 未讀訊息數量
 * @param { type Number(數字) } lastMsgAt 最後一筆聊天室訊息內容時間
 * @param { type String(字串) } lastMessage 最後一筆聊天室訊息內容
 * @param { type String(字串) } loginUserId 登入使用者 id
 * @param { type String(字串) } receiveUserId 聊天對象使用者 id
 */
export const firebaseSetUnReadCountAndLastMessage = async (unReadCount, lastMsgAt, lastMessage, loginUserId, receiveUserId) => {
    // 傳送資料
    const data = {
        unReadMessageCount: unReadCount,
        message: lastMessage,
        lastMsgAt: -lastMsgAt,
    };
    // 設定未讀訊息數量 以及 最後一筆訊息內容 與 最後一筆訊息時間
    await firebaseUnReadCountAndLastMessage(data, loginUserId, receiveUserId);
};

/**
 * 將客服切換為真人客服非機器人
 * @param { type String(字串) } loginUserId  登入使用者id
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 */
export const firebaseSetServiceChatToReal = async (loginUserId, receiveUserId) => {
    try {
        await db().doc(`chat_rooms/${loginUserId}/users/${receiveUserId}`).update({ isBot: false, updatedAt: moment().valueOf() });
        return { success: true };
    } catch (err) {
        console.log("切換為真人客服失敗", err);
        return Message({
            type: "error",
            message: "切換為真人客服失敗",
        });
    }
};

/**
 * 將聊天室已讀訊息時間記錄在聊天對象與登入者的聊天室中
 * @param { type String(字串) } receiveUserId 登入使用者id
 * @param { type String(字串) } loginUserId 聊天對象使用者id
 * @returns
 */
export const firebaseSetReadedTime = async (receiveUserId, loginUserId) => {
    try {
        await db()
            .doc(`chat_rooms/${receiveUserId}/users/${loginUserId}`)
            .update({ readedAt: moment().valueOf() + 1000 });
    } catch (err) {
        console.log("設定已讀時間失敗", err);
        return err;
    }
};

/**
 * 聊天室對象 未讀訊息歸 0
 * @param { type String(字串) } loginUserId  登入使用者id
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 */
export const firebaseMessageReaded = async (loginUserId, receiveUserId) => {
    try {
        await db().doc(`chat_rooms/${loginUserId}/users/${receiveUserId}`).update({ unReadMessageCount: 0, updatedAt: moment().valueOf() });
        return { success: true };
    } catch (err) {
        console.log("設定未讀訊息數量歸0失敗", err);
        return err;
    }
};

/**
 * 取得登入使用者 firestore 聊天對象 id
 * @param { type String(字串) } loginUserId  登入使用者id
 * @returns
 */
export const firebaseChatRoomUserKeys = async (loginUserId) => {
    try {
        const chatRoomUsers = await db().collection(`chat_rooms/${loginUserId}/users`).get();
        if (chatRoomUsers.empty) {
            return [];
        }

        const keys = [];

        const getKeys = function () {
            return new Promise((resolve) => {
                chatRoomUsers.forEach((user) => {
                    keys.push(user.id);
                });
                resolve(keys);
            });
        };
        return await getKeys();
    } catch (err) {
        console.log(err);
        return { err };
    }
};

/**
 * 更新聊天對象資料
 * @param { type String(字串) } loginUserId  登入使用者id
 * @param { type Array(陣列) } receiveUserIds 聊天對象 id
 * @param { type Object(物件) } updateData 更新資料
 * @returns
 */
export const firebaseChatRoomUsersDataUpdate = async (loginUserId, receiveUserIds, updateData) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        const paths = [];
        for (let i = 0; i < receiveUserIds.length; i++) {
            // 判斷此聊天對象未聊過天時 不執行
            const result = await firebaseCheckReceiveUserChatRoomEmpty(receiveUserIds[i], loginUserId);
            if (!result) {
                paths.push(db().doc(`chat_rooms/${receiveUserIds[i]}/users/${loginUserId}`));
            }
        }
        const batch = db().batch();
        /**
         * firestore 批量更新方式 最多一次更新 500 筆
         * 因此寫了一個演算機制 陣列資料 會以 500 筆為一個 單位
         * 超過 500 筆就會有兩個陣列資料 超過 1000筆 就會有三個陣列資料 以此類推
         */
        // 可被整除的數字 (取出可被500整除的最大公倍數，當陣列數小於 500 時 給予預設值 1)
        const divisble = parseInt(paths.length / 500) < 0 ? 1 : parseInt(paths.length / 500);
        // 需要更新的所有路徑 (將陣列資料 以 500 筆 為單位 拆成 二維陣列資料方式存入)
        const pathsTotal = [];
        // 判斷最大公倍數有多少執行回圈多少次
        for (let i = 0; i < divisble; i++) {
            pathsTotal[i] = paths.slice(i * 500, (i + 1) * 500);
        }
        // 陣列數 / 500後 如果未整除時 將剩餘陣列資料塞入 pathsToatal 中
        if (paths.length / 500 > divisble) {
            // 新增一筆陣列資料將最後剩餘的資料塞入
            pathsTotal[divisble] = paths.splice(divisble * 500, paths.length);
        }
        // 更新聊天對象資料
        pathsTotal.forEach(async (paths) => {
            paths.forEach(async (path) => {
                try {
                    await batch.update(path, { userData: updateData });
                } catch (err) {
                    console.log("批量更新聊天對象資料失敗", err);
                    reject();
                }
            });
            await batch.commit();
        });
        resolve();
    });
};

/**
 * 更新指定對象 chat_rooms 資料
 * @param { type String(字串) } userId 使用者 id
 * @param { type Object(物件) } userData 更新資料
 */
export const firebaseUpdateChatRoomUserData = async (userId, userData) => {
    try {
        await db().doc(`chat_rooms/${userId}`).update({ userData });
        return;
    } catch (err) {
        console.log("更新chat_rooms指定對象資料失敗", err);
        return;
    }
};

// 取得客服聊天室未讀訊息總計
export const firebaseGetServcieChatUnReadMessageCount = async () => {
    try {
        const doc = await db().doc(`chat_rooms/${process.env.VUE_APP_SERVICE_CHAT_ID}`).get();
        // 未讀訊息總計預設值
        const defaultData = {
            unReadMessageCount: 0,
            unReadMessageCountByProvider: 0,
            unReadMessageCountByMember: 0,
        };
        // 判斷客服聊天室沒有資料時給予預設值
        if (!doc.exists) {
            return defaultData;
        }
        // 判斷未設定過未讀訊息總計時 給予預設值
        if (!Object.prototype.hasOwnProperty.call(doc.data(), "unReadMessageCount")) {
            return defaultData;
        }
        return {
            unReadMessageCount: doc.data().unReadMessageCount !== undefined ? doc.data().unReadMessageCount : 0,
            unReadMessageCountByProvider: doc.data().unReadMessageCountByProvider !== undefined ? doc.data().unReadMessageCountByProvider : 0,
            unReadMessageCountByMember: doc.data().unReadMessageCountByMember !== undefined ? doc.data().unReadMessageCountByMember : 0,
        };
    } catch (err) {
        console.log("取得客服聊天室未讀訊息數量失敗", err);
        return err;
    }
};

/**
 * 更新客服未讀訊息累計
 * @param { type Number(數字) } unReadMessageCount 所有未讀訊息數量總計
 * @param { type Number(數字) } unReadMessageCountByProvider 服務商未讀訊息數量總計
 * @param { type Number(數字) } unReadMessageCountByMember 會員未讀訊息數量總計
 * @returns
 */
export const firebaseUpdateServiceChatUnReadMessageCount = async (unReadMessageCount, unReadMessageCountByProvider, unReadMessageCountByMember) => {
    try {
        await db().doc(`chat_rooms/${process.env.VUE_APP_SERVICE_CHAT_ID}`).update({
            unReadMessageCount,
            unReadMessageCountByProvider,
            unReadMessageCountByMember,
        });
    } catch (err) {
        console.log("更新客服聊天室未讀訊息失敗", err);
        return err;
    }
};

/**
 * 設定客服為機器人
 * @param { type String(字串) } loginUserId  登入使用者id
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 */
export const firebaseSetServiceChatToBot = async (loginUserId, receiveUserId) => {
    try {
        await db().doc(`chat_rooms/${loginUserId}/users/${receiveUserId}`).update({ isBot: true, updatedAt: moment().valueOf() });
        return { success: true };
    } catch (err) {
        console.log(err);
        return { err };
    }
};
